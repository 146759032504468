import React, { ReactElement, ChangeEvent, KeyboardEvent } from 'react'
import { CustomMUITextField } from '../CustomMUI/CustomMUITextField'

interface CustomTextFieldProps {
  fieldName: string
  label: string
  value: string
  onChanged: (key: string, value: string) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  className?: string
  required?: boolean
  multiline?: boolean
  rowsMax?: number
  rows?: number
  helperText?: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
}

export const CustomTextField: React.FC<CustomTextFieldProps> = (props: CustomTextFieldProps): ReactElement => {
  const {
    className,
    fieldName,
    label,
    required,
    multiline,
    rowsMax,
    rows,
    value,
    disabled,
    helperText,
    error,
    placeholder,
    onChanged,
    onKeyDown,
  } = props

  return (
    <CustomMUITextField
      className={`${className} custom-css-outlined-input`}
      label={label}
      value={value || ''}
      disabled={disabled}
      required={required}
      multiline={multiline}
      rows={rows}
      maxRows={rowsMax}
      helperText={error ? helperText : null}
      error={error}
      placeholder={placeholder}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChanged(fieldName, event.target.value)
      }}
      onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(event)}
      variant="outlined"
      id={`custom-css-outlined-input-${fieldName}`}
    />
  )
}
