import React from 'react'
import { AppBar, Toolbar, IconButton } from '@material-ui/core'
import MenuIcon from "@material-ui/icons/Menu";
import { useStyles } from './styles'
import clsx from 'clsx'
import { Images } from '../../../constants/images'
import './index.scss'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom';

export const TurnRoyalToolbarLandingPage = (props: any) => {
  const classes = useStyles()
  const { t } = useTranslation('translations')

  return (
    <div className='toolbar-landing'>
      <AppBar position="static" classes={{
        root: classes.appbar
      }}>
        <Toolbar>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => props.toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={
            clsx(classes.sectionDesktop, 'section-desktop')
          }>
            <div className='left'>
              <NavLink to='/turn2royal' className='app-icon-container'>
                <h1 className='title'>Turn2Royal</h1>
              </NavLink>
              <NavLink to='/turn2royal/policy' className='item-container'>
                <p className='text'>
                  {t('policy')}
                </p>
              </NavLink>
              <NavLink to='/turn2royal/terms-of-use' className='item-container'>
                <p className='text'>
                  {t('termOfUse')}
                </p>
              </NavLink>
              <NavLink to='/turn2royal/contact' className='item-container'>
                <p className='text'>
                  {t('contactUs')}
                </p>
              </NavLink>
            </div>
            <div className='right'>
              <img src={Images.Facebook} className='mr-20' alt='Search' />
              <img src={Images.Instagram} className='mr-20' alt='User' />
              <img src={Images.Twitter} className='mr-20' alt='Shopping cart' />
              <img src={Images.Pinterest} className='mr-20' alt='Shopping cart' />
              <img src={Images.Dribbble} className='mr-20' alt='Shopping cart' />
            </div>
          </div>
          <div className={
            clsx(classes.sectionMobile, 'section-mobile')
          }>
            <div className='logo-app'>
              <h1 className='title'>Turn2Royal</h1>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}