import React from 'react'
import { Grid, InputAdornment, Accordion, AccordionSummary, Typography, AccordionDetails, Button } from '@material-ui/core'
import { Images } from '../../constants/images'
import { NavLink } from 'react-router-dom';
import { CustomMUITextField } from '../../components/elements/CustomMUI/CustomMUITextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FAQ } from './constant'
import clsx from 'clsx'
import './index.scss'

export const PetartSupport = () => {
  const [expanded, setExpanded] = React.useState([] as number[])
  const [searchStr, setSearchStr] = React.useState('' as string)

  const updateExpanded = (value: number) => {
    let originalArr = [...expanded]
    if (originalArr.includes(value)) {
      originalArr = originalArr.filter(i => i !== value)
      setExpanded(originalArr)
    } else {
      setExpanded([...originalArr, value])
    }
  }

  const renderText = (value: string) => {
    let arr = value.split('3week.net@gmail.com')
    if (arr.length > 1)
      return (
        <p>{arr[0]}<span style={{ color: 'red' }}>3week.net@gmail.com</span>{arr[1]}</p>
      )
    return arr[0].split('\n').map(str => <p>{str}</p>)
  }

  return (
    <Grid container className='support' >
      <Grid item container lg={12} className='header'>
        <Grid item container lg={12} className='headline'>
          <NavLink to='/' className='app-icon-container'>
            <img src={Images.AppIcon} className='main' />
            <h1 className='title'>PetArt</h1>
          </NavLink>
        </Grid>
        <Grid className='image'>
          <img src={Images.BackGroundHeader} />
        </Grid>
        <Grid className='input'>
          <CustomMUITextField
            className='input-ios'
            placeholder='What can we help you with?'
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Images.IconSearch} className='startAdorment' />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container lg={12} className='content' justifyContent='center' alignItems='center'>
        <Grid item lg={12} className='headline'>
          <img src={Images.IconImage} className='image' />
          <p className='text'>My Custom Pet Art</p>
        </Grid>
        <Grid item lg={10} md={10} sm={11} xs={11} justifyContent='center' alignItems='center'>
          {FAQ.filter((item) => item.title.split(' ').join('').toLocaleLowerCase().includes(searchStr.split(' ').join('').toLocaleLowerCase())).map((item, index) => {
            return (
              <Accordion key={index} className='accordin' expanded={expanded.includes(index)} onChange={() => updateExpanded(index)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={clsx('accordin-title', {
                    active: expanded.includes(index)
                  })}>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='accordin-content'>
                    {renderText(item.content)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Grid>
      </Grid>
      <Grid item container lg={12} className='bottom' justifyContent='center' alignItems='center' direction='column'>
        <p className='headline'>Can't find your answer?</p>
        <p className='describe'>Contact our 24/7 customer support team and we'll be happy to help you out.</p>
        <Button color="primary" className='button'>
          <NavLink to='/petart/contact'>
            <p className="text-contact">Contact support</p>
          </NavLink>
        </Button>
      </Grid>
    </Grid>
  )
}