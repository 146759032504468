import React, { useState, useEffect, ReactElement } from 'react'
import clsx from 'clsx'
import { Drawer, IconButton, List } from '@material-ui/core'
import { DefaultMenus } from './constants'
import { GroupMenu } from '../../elements/GroupMenu'
import { useStyles } from './styles'
import './index.scss'
import { ToastContainer } from 'react-toastify';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

interface DrawerLandingPageProps {
  isOpen: boolean
  onclose: () => void
}

interface DrawerLandingPageState {
  itemActive: string
}

export const DrawerLandingPage: React.FC<DrawerLandingPageProps> = (props: DrawerLandingPageProps): ReactElement => {
  const classes = useStyles()
  const [state, setState] = useState({
    itemActive: '',
  })

  useEffect(() => {
    const url = window.location.href
    const itemActive = url.split('/')[3]
    handleChangeState('itemActive', itemActive)
  }, [])

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: DrawerLandingPageState) => ({
      ...prevState,
      [key]: value as Pick<DrawerLandingPageState, keyof DrawerLandingPageState>,
    }))
  }

  const handleChangeItemActive = (value: string) => {
    props.onclose()
    handleChangeState('itemActive', value)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={props.isOpen}
    >
      <div
        className={clsx(classes.toolbar, {
          'justify-content-center': !props.isOpen,
        })}
      >
        <IconButton onClick={() => props.onclose()}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <List className='mb-70'>
        <GroupMenu
          menus={DefaultMenus}
          isOpen={props.isOpen}
          itemActive={state.itemActive}
          handleChangeItemActive={handleChangeItemActive}
        />
      </List>
      <ToastContainer />
    </Drawer>
  )
}
