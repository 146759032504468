export const FAQ = [
  {
    title: 'Do you just make art of human?',
    content: 'Our artists are incredibly talented and can design a portrait for any human & animal on Earth.'
  },
  {
    title: 'Do you do pet portraits?',
    content: 'We are happy to do a pet and owner together'
  },
  {
    title: 'Can I order more than one face on the artwork?',
    content: 'Yes! We have a variety of options to suit your needs. Right now, we have costume options for 1 face, 2 faces, 3 faces and even 4 faces together in one portrait!',
  },
  {
    title: 'How do you make your Turn2Royal ?',
    content: 'Our graphic designers are fully equipped to make just about any digital enhancement to your photos that you’d like. We can enhance colors, merge several images into a single composition, cut out any unnecessary imagery in the background, and so much more! Just about any request is possible with our outstanding editing team.'
  },
  {
    title: 'How long does it take to create my artwork?',
    content: 'Our artists will create your artwork in 5-7 business days'
  },
  {
    title: 'What file format images can I upload to Photos to Turn2Royal?',
    content: 'You may upload photos that are formatted as “jpg”, “tiff”, “pdf”, “png”, or “gif”'
  },
  {
    title: 'Can you add or remove something from my photo?',
    content: `We can't offer to add things like accessories, extra background colors or extra bits to your chosen costume. We are very proud of our work and like to keep things uniform
However, if you is wearing an accessory in the original photo, we will always try to include it.
The same goes for collars - we do remove collars for almost all costumes but it's always best for those that show some neck (e.g. The Duchess), that you provide a photo of you without a collar on.`
  },
  {
    title: 'Cannot upload your photo?',
    content: `No worries, we've got you covered! If your photo is too "heavy" or is of a format that our app doesn't accept, you can continue placing your order and send it as an attachment directly to 3week.net@gmail.com. Our team will upload the photo to your order for you.`
  },
  {
    title: 'Can you change the background of my photo?',
    content: `Don't worry if your pet is rather dark, we will always adjust the background shade to highlight you.`
  },
  {
    title: 'Can I change my order ?',
    content: `You can change your order up to 3 hours after you place it. If you placed your order more than 3 hours ago, we can't guarantee that we can change it. The reason for this is that our artists begin work as soon as you have placed your order.
If you're unhappy with the artwork specifically, we'll check to see if you submitted a photo that follows our photo tips. If you didn't follow our guides I'm afraid it's likely we can't help you. If you did follow the guide and it's clear we missed something, we'll correct it and send you a replacement.
Make sure you provide a clear, high quality photo taken in good lighting and at eye-level with you. We use the exact photo you submit during our design process. If the photo isn't high quality, your design might not be either.`
  },
  {
    title: 'Can I cancel my order ?',
    content: `We can't accept any cancellation requests because your artist will have been assigned and will have already spent time working on your artwork by this point.`
  },
  {
    title: `What if I don't like the artwork?`,
    content: `We ensure you will love your artwork!
We are dedicated to ensuring you're happy with the final product. If you don't like it for any reason, please reach out to our support team at 3week.net@gmail.com and we'll do our best to help you.`
  },
  {
    title: `How do I create an account?`,
    content: `To create an account you simply need to select the “Sign Up” in More screen. Enter your email address in the “Email Address” field and click on the “Create an account” button. From there you will need to complete the User name and Password fields, then select “Register”.`
  },
  {
    title: ` What is your refund policy?`,
    content: `We cannot offer returns because of customized nature of art products we produce. Your artwork is your personal and unique product with your face.
    Please contact us if you unhappy with your art product or have any problems with it. Customer satisfaction is very important to us and we always try our best to make you completely satisfied and happy with the result.
    If you received the wrong product, or incorrect size, please contact us within 48 hours of receiving your order and attach photos of the product received. Then we will issue you a replacement`
  },
  {
    title: 'How long do I have to wait for a reply to my email?',
    content: `You should expect to receive a response within a couple of hours.
During busier periods (such as holiday shopping season), please allow 24-48 hours for a response.`
  },
  {
    title: 'What are my payment options?',
    content: `You can buy inside our app. We offer consumable in‑app purchases directly on the App Store.`
  },
  {
    title: 'When will I be charged?',
    content: `All orders will be charged in full at the point of purchase.`
  },
]