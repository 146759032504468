import { Images } from '../../../constants/images'
import { SideBarItem } from '../../../interfaces/common/menu'

export const DefaultMenus = [
  {
    path: '/petart',
    name: 'Home',
    activePath: '',
  },
  {
    path: 'petart/terms-of-use',
    name: 'Terms of use',
    activePath: 'term-of-use',
  },
  {
    path: 'petart/policy',
    name: 'Privacy Policy',
    activePath: 'policy',
  },
  {
    path: 'petart/contact',
    name: 'Contact Us',
    activePath: 'contact',
  },
]
