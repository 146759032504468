import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import createMyTheme from '../assets/styles/Material-ui/styles';
import history from './utils/history'
import { PetartLandingPage } from './containers/PetartLandingPage';
import { PetartSupport } from './containers/PetartSupport';
import { I18nextProvider } from 'react-i18next'
import { PetartContact } from './containers/PetartContact';
import { Tica } from './containers/tica';
import i18next from 'i18next'
import './config/i18n'
import { PetartPhotoGuide } from './components/resources/PetartPhotoGuide';
import { PetartPolicy } from './components/resources/PetartPolicy';
import { PetartTermOfUse } from './components/resources/PetartTermOfUse';
import { Home } from './containers/home';
import { TOS } from './containers/tos';
import { Privacy } from './containers/Privacy';
import { Inspiration } from './containers/Inspiration';
import { FAQ } from './containers/Faq';
import { Contact3w } from './containers/Contact3w';
import { BabyFoodHome } from './containers/BabyFoodHome';
import { TurnRoyalLandingPage } from './containers/TurnRoyalLandingPage';
import { TurnRoyalPhotoGuide } from './components/resources/TurnRoyalPhotoGuide';
import { TurnroyalSupport } from './containers/TurnroyalSupport';
import { TurnroyalContact } from './containers/TurnroyalContact';
import { TurnRoyalTermOfUse } from './components/resources/TurnRoyalTermOfUse';
import { TurnRoyalPolicy } from './components/resources/TurnRoyalPolicy';

const theme = createMyTheme({
  colors: {
    MainPink: '#D75F8D',
    PastelPink: '#E28BAE',
    Peach: '#FFB7B7',
    Beige: '#EFD4C1',
    Coral: '#E1ABA8',
    CoolBlue: '#9DB6DF',
    LightBlue: '#A2CBE3',
    LightGreen: '#C4E1C9',
    MainGrey: '#747474',
    DarkGrey: '#262626',
    HalfGrey: '#B1B1B1',
    LightGrey1: '#DBDBDB',
    LightGrey2: '#E5E5E5',
    VeryLightGrey: '#F7F7F7',
    Red: '#EB5757',
    White: '#FFFFFF',
  },
})

function App() {
  useEffect(() => {
    const pathname = window.location.search;
    if (pathname === '?photo-guide') {
      history.push('/petart/photo-guide')
    }

    if (pathname === '?terms-of-use') {
      history.push('/petart/terms-of-use')
    }

    if (pathname === '?policy') {
      history.push('/petart/policy')
    }

    if (pathname === '?support') {
      history.push('/petart/support')
    }

    if (pathname === '?contact') {
      history.push('/petart/contact')
    }

  }, [])


  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Switch>
              <Route path='/petart' component={PetartLandingPage} exact={true} />
              <Route path='/petart/photo-guide' component={PetartPhotoGuide} />
              <Route path='/petart/support' component={PetartSupport} />
              <Route path='/petart/contact' component={PetartContact} />
              <Route path='/petart/terms-of-use' component={PetartTermOfUse} />
              <Route path='/petart/policy' component={PetartPolicy} />
              <Route path='/turn2royal' component={TurnRoyalLandingPage} exact={true} />
              <Route path='/turn2royal/photo-guide' component={TurnRoyalPhotoGuide} />
              <Route path='/turn2royal/support' component={TurnroyalSupport} />
              <Route path='/turn2royal/contact' component={TurnroyalContact} />
              <Route path='/turn2royal/terms-of-use' component={TurnRoyalTermOfUse} />
              <Route path='/turn2royal/policy' component={TurnRoyalPolicy} />
              <Route path='/tica.html' component={Tica} exact={true} />
              <Route path='/tos.html' component={TOS} exact={true} />
              <Route path='/privacy.html' component={Privacy} exact={true} />
              <Route path='/inspiration.html' component={Inspiration} exact={true} />
              <Route path='/faq.html' component={FAQ} exact={true} />
              <Route path='/contact' component={Contact3w} exact={true} />
              <Route path='/babyfood' component={BabyFoodHome} />
              <Route path='/' component={Home} exact={true} />
            </Switch>
          </Router>
        </ThemeProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
