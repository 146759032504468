import React, { ReactElement, useState } from 'react'
import { ToolbarLandingPage } from '../../components/resources/ToolbarLandingPage'
import { DrawerLandingPage } from '../../components/resources/DrawerLandingPage'
import { Images } from '../../constants/images'
import { Grid, InputAdornment } from '@material-ui/core'
import { CustomMUITextField } from '../../components/elements/CustomMUI/CustomMUITextField'
import './index.scss'
import { Switch, Route, NavLink } from 'react-router-dom'
import { Home } from '../../components/resources/Home'

export const PetartLandingPage: React.FC = (): ReactElement => {
  const [state, setState] = useState({
    isOpenDrawer: false,
  })

  const handleChangeState = (key: string, value: unknown) => {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  return (
    <div className='landing' >
      <ToolbarLandingPage toggleDrawer={() => handleChangeState('isOpenDrawer', !state.isOpenDrawer)} />
      <DrawerLandingPage isOpen={state.isOpenDrawer} onclose={() => handleChangeState('isOpenDrawer', false)} />
      <Switch>
        <Route path='/' component={Home} />
      </Switch>
      <Grid container className='footer'>
        <Grid item container xs={12} className='top'>
          <Grid item lg={3} md={3} sm={12} xs={12} className='group'>
            <NavLink to='/petart/terms-of-use' className='title'>Terms of use</NavLink>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className='group'>
            <NavLink to='/petart/policy' className='title'>Privacy Policy</NavLink>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className='group'>
            <NavLink to='/petart/contact' className='title'>Contact Us</NavLink>
          </Grid>
        </Grid>
        <Grid item container xs={12} className='bottom'>
          <Grid item lg={5} md={5} sm={12} xs={12} className='text'>
            <h1 className='title'>PetArt</h1>
            <p className='coppy-right'>&#169; PetArt 2021</p>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12} className='contacts'>
            <div className='right'>
              <img src={Images.FacebookBlack} className='mr-20' alt='Search' />
              <img src={Images.Instagram} className='mr-20' alt='User' />
              <img src={Images.TwitterBlack} className='mr-20' alt='Shopping cart' />
              <img src={Images.PinterestBlack} className='mr-20' alt='Shopping cart' />
              <img src={Images.DribbbleBlack} className='mr-20' alt='Shopping cart' />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
} 
