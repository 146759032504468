import React from 'react'
import { Grid } from '@material-ui/core'
import { Images } from '../../../constants/images'
import './index.scss'
import YouTube, { Options } from 'react-youtube';

export const TurnRoyalPhotoGuide = () => {
  const opts: Options = {
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Grid className='photoGuide'>
      <Grid container item xs={12} className='container'>
        <p className='title'>Good Photo Guide</p>
        <p className='text'>Follow the tips in this video and the Example Images below when taking your photo</p>
        <YouTube videoId="P-2ykRw4_MQ" opts={opts} className='youtube' />
        <p className='text'>If your photo doesn't meet our criteria, we'll let you know and help you choose a more suitable one!</p>
        <p className='text'> • Make sure the photo is taken in good lighting (outdoor daylight is best)</p>
        <p className='text'> • Try to take the photo at <strong>eye level</strong> with your photo</p>
        <p className='text'> • They don't need to be looking at the camera</p>
        <p className='text'> • Try to get a <strong>close up</strong> so we can see your unique features</p>
        <p className='text'> • Make sure the photo is not blurry and high resolution ( 1.5 Mb + )</p>
        <p className='text'> • Make sure no features (e.g. ears!) are out of frame - we can't add them if they aren't in the original photo</p>
        <p className='text'> • Ensure that the fur color in the photo is not affected by bad lighting. If the fur in the photo is not accurate to real life, the fur in the design won't be either</p>
        <p className='special-text'><strong>To Ensure the quality of your product, please submit original photos, don't use screenshots - cropped out photos, etc (just use these in case you have no other options). </strong></p>
        <img src={Images.TurnRoyalGoodPhotoGuide} className='img' />
      </Grid>
    </Grid>
  )
}