import React from 'react'
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { Images } from '../../../constants/images'
import { useTranslation } from 'react-i18next';
import { useStyles } from '../ToolbarLandingPage/styles'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import '../PetartTermOfUse/index.scss'

export const PetartPolicy = () => {
  return (
    <Grid className='terms-of-use'>
      <Grid container item xs={12} className='container'>
        <p className='title' style={{ textTransform: 'uppercase' }}>Privacy policy</p>
        <p className='text'>
          By using this mobile application (the "Application"), you agree to be bound by this PetArt ("us", "we", or "our")
        </p>
        <p className='text'>Privacy Policy in addition to such other terms of use made available to you in connection with the Application. Certain features of the Application may require you to be a registered member or provide personal information.</p>
        <p className='text'>This Privacy Policy describes our policies with the collection, use and disclosure of Personal Information. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
        <p className='text'>This policy is effective from 1th of January, 2021.</p>
        <p className='text'>
          <b>What we collect </b>
        </p>
        <p className='text'>
          We may collect and store information you make available to us: contact information.
        </p>
        <p className='text'>In addition, the Application may collect and store additional information automatically, such as:</p>
        <p className='text'>- Email address</p>
        <p className='text'>- First & Last Name</p>
        <p className='text'>- The photos that you upload to ask us design for you.</p>
        <p className='text'>- Device registration data (e.g., the type of mobile device you use, your mobile device’s unique device or advertising ID, IP address, operating system and browser type)</p>
        <p className='text'>- Device settings (e.g., language preference)</p>
        <p className='text'>- Mobile carrier</p>
        <p className='text'>- Information about how you use the Application (for example, how many times you use the Application each day)</p>
        <p className='text'>- Requested and referring URLs</p>
        <p className='text'>- Location data collected through your device (including, for example, precise location data such as GPS and WiFi information)</p>

        <p className='text'>
          <b>Advertising, Third party and social services:</b>
        </p>
        <p className='text'>
          PetArt may rely on third-party advertisers, ad networks and ad servers to promote the Application. These third parties may use cookies or other tracking technologies to collect information about our users. This may include information about users' behavior on this app to serve them interested-based (behavioral) advertising. No information you share within the Application will be shared with third-party advertisers. We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement, you should contact the responsible advertiser directly.
        </p>
        <p className='text'>
          The Application may also utilize social networking or “share functionality” or may contain links to third party sites that are not owned or controlled by PetTrait. We have no control over, and assumes no responsibility for, any share functionality or the content, privacy policies, or practices of any third-party site. You are subject to the policies of those third parties when and where applicable. You expressly relieve PetTrait from any and all liability arising from your use of any share functionality or third-party site accessed from the Application.
        </p>

        <p className='text'>
          <b>What kind of reports Our Application will show you?</b>
        </p>
        <p className='text'>
          Our Application is an entertainment service and we do not guarantee to give you 100% real reports. It was only designed to make you having fun. You accept that we do not guarantee to give you exact true reports by downloading and upgrading your membership.
        </p>
        <p className='text'>
          What we do with the information we gather?
        </p>
        <p className='text'>
          We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
        </p>
        <p className='text'>
          - App experience
        </p>
        <p className='text'>
          - Information to improve our products and services.
        </p>
        <p className='text'>
          - We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.
        </p>

        <p className='text'>
          <b>Security</b>
        </p>
        <p className='text'>
          We are committed to ensuring that your information is secure.
        </p>
        <p className='text'>
          In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
        </p>

        <p className='text'>
          <b>How we use cookies</b>
        </p>
        <p className='text'>
          A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our application in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
        </p>
        <p className='text'>
          Overall, cookies help us provide you with a better application, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the application.
        </p>

        <p className='text'>
          <b>Links to other applications</b>
        </p>
        <p className='text'>
          Our app may contain links to other content of interest. However, once you have used these links to leave our app, you should note that we do not have any control over that other content. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the application in question.
        </p>

        <p className='text'>
          <b>Controlling your personal information</b>
        </p>
        <p className='text'>
          You may choose to restrict the collection or use of your personal information by not accepting access to your details in the Auth Dialog box. You can also withdraw permissions by the app to access your data by going to the settings. We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about our own content which we think you may find interesting. You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please contact us. If you believe that any information we are holding on you is incorrect or incomplete, please contact us as soon as possible. We will promptly correct any information found to be incorrect.
        </p>

        <p className='text'>
          <b>Children’s Privacy</b>
        </p>
        <p className='text'>
          We respect the privacy of children under the terms of COPPA. We do not knowingly collect or solicit any information from anyone under the age of 13 or knowingly allow such persons to register for the application. Our application and its content are not directed at children under the age of 13. In the event that we learn that we have collected personal information from a child under age 13 without parental consent, we will delete that information as quickly as possible.
        </p>

        <p className='text'>
          <b>Change to Our Privacy Policy</b>
        </p>
        <p className='text'>
          This Privacy Policy may be updated from time to time for any reason. Modifications will be effective immediately. You are advised to consult this page periodically for any changes. We will notify you of any changes to this Privacy Policy by posting on the Application.
        </p>
        <p className='text'>
          For questions or suggestions about this Privacy Policy, please contact us at link. We will do our best to respond to you in a timely and professional manner to answer your questions and resolve your concerns.
        </p>
      </Grid>
    </Grid>
  )
}