import React, { useState, Fragment, useEffect } from 'react'
import { Grid, InputAdornment, Button } from '@material-ui/core'
import { Images } from '../../constants/images'
import { NavLink } from 'react-router-dom';
import { CustomMUITextField } from '../../components/elements/CustomMUI/CustomMUITextField'
import './index.scss'
import { CustomTextField } from '../../components/elements/CustomTextField';
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify';
import { SkeletonRegistration } from '../../components/elements/Skeleton'

const initialState = {
  suggestion: '',
  email: '',
  username: '',
  phone: '',
  description: ''
}

export const TurnroyalContact = () => {
  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(false)

  useEffect(()=> {
    window.location = "mailto:turn2royal.app@gmail.com?body=1. I have some suggestions to share with you:%0D%0A%0D%0A%0D%0A%0D%0A 2. I want complain about:%0D%0A%0D%0A%0D%0A%0D%0A 3. I love Inspiration about:%0D%0A%0D%0A%0D%0A" as any;
  },[])

  const submit = () => {
    if (state.email && state.description) {
      setLoading(true)
      emailjs.send('service_0ajajnu', 'template_1srwtq5', state, 'user_JeifYxmtn6PTOEb97k2D8')
        .then((response) => {
          setState(initialState)
          setLoading(false)
          toast.success("Success!")
        }, (err) => {
          setState(initialState)
          setLoading(false)
          toast.error("Failure!")
        });
    } else {
      toast.error("Must not be blank!")
    }
  }

  const handleChangeState = (key: string, value: unknown) => {
    setState(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const render = () => {
    if (loading) {
      return <SkeletonRegistration />
    }
    return (
      <Fragment>
        <Grid item container lg={12} className='content' justifyContent='center' alignItems='center'>
          <h1 className='title'>
            Submit a request
        </h1>
          <Grid item container lg={9} md={9} sm={11} xs={11}>
            <Grid item container lg={12} className='combo-text-field' direction='column'>
              <p className='title'>What can we help you with?</p>
              <CustomTextField
                value={state.suggestion}
                fieldName='suggestion'
                label=''
                onChanged={handleChangeState}
                className='field'
              />
            </Grid>
          </Grid>
          <Grid item container lg={9} md={9} sm={11} xs={11}>
            <Grid item container lg={12} className='combo-text-field' direction='column'>
              <p className='title'>Your email address<span style={{ color: '#FF97AB', marginLeft: 10 }}>*</span></p>
              <CustomTextField
                value={state.email}
                fieldName='email'
                label=''
                onChanged={handleChangeState}
                required
                className='field'
              />
            </Grid>
          </Grid>
          <Grid item container lg={9} md={9} sm={11} xs={11}>
            <Grid item container lg={12} className='combo-text-field' direction='column'>
              <p className='title'>Full Name</p>
              <CustomTextField
                value={state.username}
                fieldName='username'
                label=''
                onChanged={handleChangeState}
                className='field'
              />
            </Grid>
          </Grid>
          <Grid item container lg={9} md={9} sm={11} xs={11}>
            <Grid item container lg={12} className='combo-text-field' direction='column'>
              <p className='title'>Phone number</p>
              <CustomTextField
                value={state.phone}
                fieldName='phone'
                label=''
                onChanged={handleChangeState}
                className='field'
              />
            </Grid>
          </Grid>
          <Grid item container lg={9} md={9} sm={11} xs={11}>
            <Grid item container lg={12} className='combo-text-field' direction='column'>
              <p className='title'>What information should we know?<span style={{ color: '#FF97AB', marginLeft: 10 }}>*</span></p>
              <CustomTextField
                value={state.description}
                fieldName='description'
                label=''
                required
                onChanged={handleChangeState}
                className='field'
                multiline
                rows={8}
                rowsMax={15}
              />
            </Grid>
          </Grid>
          <Grid item container lg={9} md={9} sm={11} xs={11} className='button-container' justifyContent='center' alignItems='center'>
            <Grid item container lg={2} md={2} sm={4} xs={4} className='button'>
              <Button color="secondary" className='button' onClick={() => submit()}>
                <p className="text-submit">Send</p>
              </Button>
            </Grid>
          </Grid>
          <Grid item container lg={9} md={9} sm={11} xs={11} className='text-describe'>
            <p className='tag-p'>If you can't find an answer, just fill out the form below and a member of our team will be in touch within 24 hours. Alternatively you can contact us via email at turn2royal.app@gmail.com</p>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Grid container className='contact' >
      <Grid item container lg={12} className='header'>
        <Grid item container lg={12} className='headline'>
          <NavLink to='/' className='app-icon-container'>
            <img src={Images.TurnRoyalAppIcon} className='main' />
            <h1 className='title'>Turn2Royal</h1>
          </NavLink>
        </Grid>
        <Grid className='image'>
          <img src={Images.TurnRoyalBackGroundHeader} />
        </Grid>
        <Grid className='input'>
          <CustomMUITextField
            className='input-ios'
            placeholder='What can we help you with?'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Images.IconSearch} className='startAdorment' />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {render()}
      <ToastContainer />
    </Grid>
  )
}