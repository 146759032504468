import React from 'react'
import './index.scss'
import { Images } from '../../constants/images'

export const BabyFoodHome = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#FE6C63' }}>
      <div data-collapse="none" data-animation="default" data-duration={400} role="banner" className="navbar w-nav">
        <div className="nav-wrap w-container">
          <a href="/" aria-current="page" className="logo w-inline-block w--current"><img src={Images.LogoBabyFood} loading="lazy" /></a>
          <div className="nav-buttons">
            <div className="nav-socials">
              <a href="https://discord.gg/SXF2MJ8T6t" target="_blank" className="social-link w-inline-block" />
              <a href="http://instagram.com/WOMBO.ai" target="_blank" className="social-link instagram w-inline-block" />
              <a href="http://twitter.com/WOMBO" target="_blank" className="social-link twitter w-inline-block" />
              <a href="mailto:hello@wombo.ai?subject=Website%20Inquiry" className="social-link mail w-inline-block" />
            </div>
            {/* <div className="nav-cta desktop"><a href="/blog" className="button mobile-cta hidden w-button">Wombo Blog</a><a href="/" aria-current="page" className="nav-link w--current">Main</a><a href="/careers" className="nav-link">Careers</a></div> */}
          </div>
          {/* <div className="nav-cta mobile"><a href="/blog" className="button mobile-cta hidden w-button">Wombo Blog</a><a href="/" aria-current="page" className="nav-link w--current">Main</a><a href="/careers" className="nav-link">Careers</a></div> */}
        </div>
        <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
      </div>
      <div className="section hero full-page">
        <div className="container flex mobile-reverse">
          <div id="email-form" data-w-id="193f9489-2ff5-c7b6-8787-96beff6237c5" className="_50">
            <h1 data-w-id="193f9489-2ff5-c7b6-8787-96beff6237c6" className="heading" style={{opacity: 1, transform: 'translate3d(0,0,0) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg,0deg)', transformStyle: 'preserve-3d', fontSize: 32}}>HomeMade
              <br />baby food ideas
            </h1>
            <p data-w-id="193f9489-2ff5-c7b6-8787-96beff6237ca" className="paragraph" style={{opacity: 1, transform: 'translate3d(0,0,0) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg,0deg)', transformStyle: 'preserve-3d', color: '#fff'}}>
            Making your own baby food doesn't have to be hard, and it's the best way to make sure your little one enjoys all the health benefits of fresh fruits and veggies.
              <br /></p>
            <div data-w-id="8e6a123a-8728-74e8-c91e-2b1caeefdb1d" className="app-download-wrapper" style={{opacity: 1, transform: 'translate3d(0,0,0) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg,0deg)', transformStyle: 'preserve-3d'}}>
              <a href="https://apps.apple.com/app/id1531396120" target="_blank" className="mobile-download w-inline-block" />
            </div>
            <div data-w-id="193f9489-2ff5-c7b6-8787-96beff6237cf" className="form w-form" style={{opacity: 1, transform: 'translate3d(0,0,0) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg,0deg)', transformStyle: 'preserve-3d'}}>
              <div className="sub-heading black">Baby food</div>
              <form id="email-subscribe" name="wf-form-Email-Subscribe-Form" data-name="Email Subscribe Form" className="email-form">
                <div className="field-wrapper">
                  <label>Email address</label>
                  <input type="email" className="text-field w-input" maxLength={256} name="email" data-name="Email" placeholder="example@mail.com" id="email" required />
                </div>
                <input type="submit" defaultValue="Submit" data-wait="Please wait..." className="button-babyfood submit w-button" />
              </form>
              <div className="success-message w-form-done">
                <div>Thanks for signing up!</div>
              </div>
              <div className="error-message w-form-fail">
                <div>Oops! Something went wrong, please try again.</div>
              </div>
            </div>
          </div>
          <div data-w-id="193f9489-2ff5-c7b6-8787-96beff6237d9" style={{opacity: 1, transform: 'translate3d(0,0,0) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg,0deg)', transformStyle: 'preserve-3d'}} className="_50">
            <div className="hero-image" style={{marginBottom: 15}}>
              <img src={Images.BabyFoodPhone1} style={{position: 'absolute', top: 0, left: 0, zIndex: 3}} />
              <img src={Images.BabyFoodPhone2} style={{position: 'absolute', top: 0, left: '35%', zIndex: 2}} />
            </div>
            <div data-w-id="8b3b500f-efb6-a86b-aefb-8fe75f96ef9a" className="app-download-wrapper mobile-hero-cta" style={{opacity: 1, transform: 'translate3d(0,0,0) scale3d(1,1,1) rotateX(0) rotateY(0) rotateZ(0) skew(0deg,0deg)', transformStyle: 'preserve-3d'}}>
              <a href="https://apps.apple.com/app/id1531396120" target="_blank" className="mobile-download w-inline-block" />
              <a href="https://play.google.com/store/apps/details?id=com.womboai.wombo" target="_blank" className="mobile-download android w-inline-block" />
              <div data-w-id="8f01bed1-374b-c359-022f-c335d59fd710" data-animation-type="lottie" data-src="https://global-uploads.webflow.com/5fcf07e52d378eac98367eaf/60736a72205bc859accd7327_lottieflow-attention-01-f7cb2e-easey.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="3.8" data-duration={0} className="grab-attention-lottie">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1127 1032" width={1127} height={1032} preserveAspectRatio="xMidYMid meet" style={{width: '100%', height: '100%', transform: 'translate3d(0,0,0)'}}>
                  <defs>
                    <clipPath id="__lottie_element_3">
                      <rect width={1127} height={1032} x={0} y={0} />
                    </clipPath>
                  </defs>
                  <g clipPath="url(#__lottie_element_3)">
                    <g transform="matrix(1,0,0,1,0,0.3489990234375)" opacity={1} style={{display: 'block'}}>
                      <g opacity={1} transform="matrix(1,0,0,1,313.5,700.2429809570312)">
                        <path strokeLinecap="round" strokeLinejoin="round" fillOpacity={0} stroke="rgb(247,202,45)" strokeOpacity={1} strokeWidth={80} d=" M-32.604000091552734,-33.448001861572266 C-65.77100372314453,-14.298999786376953 -90.53299713134766,-0.003000000026077032 -90.53299713134766,-0.003000000026077032 C-90.53299713134766,-0.003000000026077032 -65.77100372314453,14.293999671936035 -32.604000091552734,33.44300079345703" />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,563.5,450)">
                        <path strokeLinecap="round" strokeLinejoin="round" fillOpacity={0} stroke="rgb(247,202,45)" strokeOpacity={1} strokeWidth={80} d=" M-88.05000305175781,247.68800354003906 C-103.99199676513672,249.21800231933594 -120.15399932861328,250 -136.5,250 C-136.5,250 -340.53289794921875,249.9971923828125 -340.53289794921875,249.9971923828125" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div data-w-id="ab320037-95c6-9bdf-efc1-a309afb89ef8" data-animation-type="lottie" data-src="https://global-uploads.webflow.com/5fcf07e52d378eac98367eaf/60736a72205bc859accd7327_lottieflow-attention-01-f7cb2e-easey.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="3.8" data-duration={0} className="grab-attention-lottie bottom">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1127 1032" width={1127} height={1032} preserveAspectRatio="xMidYMid meet" style={{width: '100%', height: '100%', transform: 'translate3d(0,0,0)'}}>
                  <defs>
                    <clipPath id="__lottie_element_7">
                      <rect width={1127} height={1032} x={0} y={0} />
                    </clipPath>
                  </defs>
                  <g clipPath="url(#__lottie_element_7)">
                    <g transform="matrix(1,0,0,1,0,0.3489990234375)" opacity={1} style={{display: 'block'}}>
                      <g opacity={1} transform="matrix(1,0,0,1,313.5,700.2429809570312)">
                        <path strokeLinecap="round" strokeLinejoin="round" fillOpacity={0} stroke="rgb(247,202,45)" strokeOpacity={1} strokeWidth={80} d=" M-32.604000091552734,-33.448001861572266 C-65.77100372314453,-14.298999786376953 -90.53299713134766,-0.003000000026077032 -90.53299713134766,-0.003000000026077032 C-90.53299713134766,-0.003000000026077032 -65.77100372314453,14.293999671936035 -32.604000091552734,33.44300079345703" />
                      </g>
                      <g opacity={1} transform="matrix(1,0,0,1,563.5,450)">
                        <path strokeLinecap="round" strokeLinejoin="round" fillOpacity={0} stroke="rgb(247,202,45)" strokeOpacity={1} strokeWidth={80} d=" M-88.05000305175781,247.68800354003906 C-103.99199676513672,249.21800231933594 -120.15399932861328,250 -136.5,250 C-136.5,250 -340.53289794921875,249.9971923828125 -340.53289794921875,249.9971923828125" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}