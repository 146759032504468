import React from 'react'
import { Button, Grid, InputAdornment } from '@material-ui/core'
import './index.scss'

export const PetartTermOfUse = () => {
  return (
    <Grid className='terms-of-use'>
      <Grid container item xs={12} className='container'>
        <p className='title'>TERMS OF USE</p>
        <p className='title'>LAST UPDATED: JANUARY 4,2021</p>
        <p className='text'>
          Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the mobile application (the "Service") operated by 3Week ("us", "we", or "our").
        </p>
        <p className='text'>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service.</p>
        <p className='text'>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.</p>
        <p className='text'>
          <b>Communications</b>
        </p>
        <p className='text'>
          By creating an Account on our service, you agree to subscribe to newsletters,
          marketing or promotional materials and other information we may send. However,
          you may opt out of receiving any, or all, of these communications from us by
          following the unsubscribe link or instructions provided in any email we send.
        </p>

        <p className='text'>
          <b>Subscriptions</b>
        </p>
        <p className='text'>
          Some parts of the Service are billed on a subscription basis (&quot;Subscription(s)&quot;).
          You will be billed in advance on a recurring and periodic basis (&quot;Billing Cycle&quot;).
          Billing cycles are set on a monthly basis.
        </p>
        <p className='text'>
          At the end of each Billing Cycle, your Subscription will automatically renew under
          the exact same conditions unless you cancel it or 3Week cancel it. You may
          cancel your Subscription renewal either through your iTunes account management
          page or by contacting our customer support team.
        </p>

        <p className='text'>
          <b>Fee Changes</b>
        </p>
        <p className='text'>
          3Week, in its sole discretion and at any time, may modify the Subscription fees
          for the Subscriptions. Any Subscription fee change will become effective at the
          end of the then-current Billing Cycle.
        </p>
        <p className='text'>
          3Week will provide you with a reasonable prior notice of any change in
          Subscription fees to give you an opportunity to terminate your Subscription before
          such change becomes effective.
        </p>
        <p className='text'>
          Your continued use of the Service after the Subscription fee change comes into
          effect constitutes your agreement to pay the modified Subscription fee amount.
        </p>

        <p className='text'>
          <b>Refunds</b>
        </p>
        <p className='text'>
          Except when required by law, paid Subscription fees are non-refundable.
        </p>

        <p className='text'>
          <b>Content</b>
        </p>
        <p className='text'>
          By creating an Account on our service, you agree to subscribe to newsletters,
          marketing or promotional materials and other information we may send. However,
          you may opt out of receiving any, or all, of these communications from us by
          following the unsubscribe link or instructions provided in any email we send.
        </p>

        <p className='text'>
          <b>Communications</b>
        </p>
        <p className='text'>
          Our Service allows you to post, link, store, share and otherwise make available
          certain information, text, graphics, videos, or other material (&quot;Content&quot;). You are
          responsible for the Content that you post on or through the Service, including its
          legality, reliability, and appropriateness.
        </p>
        <p className='text'>
          By posting Content on or through the Service, You represent and warrant that: (i)
          the Content is yours (you own it) and/or you have the right to use it and the right to
          grant us the rights and license as provided in these Terms, and (ii) that the posting
          of your Content on or through the Service does not violate the privacy rights,
          publicity rights, copyrights, contract rights or any other rights of any person or
          entity. We reserve the right to terminate the account of anyone found to be
          infringing on a copyright.
        </p>
        <p className='text'>
          You retain any and all of your rights to any Content you submit, post or display on
          or through the Service and you are responsible for protecting those rights. We take
          no responsibility and assume no liability for Content you or any third party posts
          on or through the Service. However, by posting Content using the Service you
          grant us the right and license to use, modify, publicly perform, publicly display,
          reproduce, and distribute such Content on and through the Service. You agree that
          this license includes the right for us to make your Content available to other users
          of the Service, who may also use your Content subject to these Terms.
        </p>
        <p className='text'>
          3Week has the right but not the obligation to monitor and edit all Content
          provided by users.
        </p>
        <p className='text'>
          In addition, Content found on or through this Service are the property of 3Week
          or used with permission. You may not distribute, modify, transmit, reuse,
          download, repost, copy, or use said Content, whether in whole or in part, for
          commercial purposes or for personal gain, without express advance written
          permission from us.
        </p>

        <p className='text'>
          <b>Accounts</b>
        </p>
        <p className='text'>
          When you create an account with us, you guarantee that you are above the age of
          18, and that the information you provide us is accurate, complete, and current at all
          times. Inaccurate, incomplete, or obsolete information may result in the immediate
          termination of your account on the Service.
        </p>
        <p className='text'>
          You are responsible for maintaining the confidentiality of your account and
          password, including but not limited to the restriction of access to your computer
          and/or account. You agree to accept responsibility for any and all activities or
          actions that occur under your account and/or password, whether your password is
          with our Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your account.
        </p>
        <p className='text'>
          You may not use as a username the name of another person or entity or that is not
          lawfully available for use, a name or trademark that is subject to any rights of
          another person or entity other than you, without appropriate authorization. You
          may not use as a username any name that is offensive, vulgar or obscene.
        </p>

        <p className='text'>
          <b>Intellectual Property</b>
        </p>
        <p className='text'>
          The Service and its original content (excluding Content
          provided by users), features and functionality are and will remain the exclusive
          property of 3Week and its licensors. The Service is protected by copyright,
          trademark, and other laws of both the United States and foreign countries. Our
          trademarks and trade dress may not be used in connection with any product or
          service without the prior written consent of 3Week.
        </p>

        <p className='text'>
          <b>Links To Other Web Sites</b>
        </p>
        <p className='text'>
          Our Service may contain links to third party web sites or services that are not
          owned or controlled by us.
        </p>
        <p className='text'>
          3Week has no control over, and assumes no responsibility for the content, privacy
          policies, or practices of any third party web sites or services. We do not warrant the
          offerings of any of these entities/individuals or their websites.
        </p>
        <p className='text'>
          You acknowledge and agree that 3Week shall not be responsible or liable, directly
          or indirectly, for any damage or loss caused or alleged to be caused by or in
          connection with use of or reliance on any such content, goods or services available
          on or through any such third party web sites or services.
        </p>
        <p className='text'>
          We strongly advise you to read the terms and conditions and privacy policies of
          any third party web sites or services that you visit.
        </p>

        <p className='text'>
          <b>Termination</b>
        </p>
        <p className='text'>
          We may terminate or suspend your account and bar access to the Service
          immediately, without prior notice or liability, under our sole discretion, for any
          reason whatsoever and without limitation, including but not limited to a breach of
          the Terms.
        </p>
        <p className='text'>
          If you wish to terminate your account, you may simply discontinue using the
          Service.
        </p>
        <p className='text'>
          All provisions of the Terms which by their nature should survive termination shall
          survive termination, including, without limitation, ownership provisions, warranty
          disclaimers, indemnity and limitations of liability.
        </p>

        <p className='text'>
          <b>Indemnification</b>
        </p>
        <p className='text'>
          You agree to defend, indemnify and hold harmless 3Week and its licensee and
          licensors, and their employees, contractors, agents, officers and directors, from and
          against any and all claims, damages, obligations, losses, liabilities, costs or debt,
          and expenses (including but not limited to attorney&#39;s fees), resulting from or arising
          out of a) your use and access of the Service, by you or any person using your
          account and password; b) a breach of these Terms, or c) Content posted on the
          Service.
        </p>

        <p className='text'>
          <b>Limitation Of Liability</b>
        </p>
        <p className='text'>
          In no event shall 3Week be liable for any indirect, incidental, special,
          consequential or punitive damages, including without limitation, loss of profits,
          data, use, goodwill, or other intangible losses, resulting from (i) your access to or
          use of or inability to access or use the Service; (ii) any conduct or content of any
          third party on the Service; (iii) any content obtained from the Service; and (iv)
          unauthorized access, use or alteration of your transmissions or content, whether
          based on warranty, contract, tort (including negligence) or any other legal theory,
          whether or not we have been informed of the possibility of such damage, and even
          if a remedy set forth herein is found to have failed of its essential purpose.
        </p>

        <p className='text'>
          <b>Disclaimer</b>
        </p>
        <p className='text'>
          Your use of the Service is at your sole risk. The Service is provided on an &quot;AS IS&quot;
          and &quot;AS AVAILABLE&quot; basis. The Service is provided without warranties of any
          kind, whether express or implied, including, but not limited to, implied warranties
          of merchantability, fitness for a particular purpose, non-infringement or course of
          performance.
        </p>
        <p className='text'>
          3Week do not warrant that a) the Service will function uninterrupted, secure or
          available at any particular time or location; b) any errors or defects will be
          corrected; c) the Service is free of viruses or other harmful components; or d) the
          results of using the Service will meet your requirements.
        </p>

        <p className='text'>
          <b>Exclusions</b>
        </p>
        <p className='text'>
          Some jurisdictions do not allow the exclusion of certain warranties or the exclusion
          or limitation of liability for consequential or incidental damages, so the limitations
          above may not apply to you.
        </p>

        <p className='text'>
          <b>Governing Law</b>
        </p>
        <p className='text'>
          These Terms shall be governed and construed in accordance with the laws of
          California, United States, without regard to its conflict of law provisions.
        </p>
        <p className='text'>
          Our failure to enforce any right or provision of these Terms will not be considered
          a waiver of those rights. If any provision of these Terms is held to be invalid or
          unenforceable by a court, the remaining provisions of these Terms will remain in
          effect. These Terms constitute the entire agreement between us regarding our
          Service, and supersede and replace any prior agreements we might have had
          between us regarding the Service.
        </p>

        <p className='text'>
          <b>Changes</b>
        </p>
        <p className='text'>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any
          time. If a revision is material we will provide at least 30 days notice prior to any
          new terms taking effect. What constitutes a material change will be determined at
          our sole discretion.
        </p>
        <p className='text'>
          By continuing to access or use our Service after any revisions become effective,
          you agree to be bound by the revised terms. If you do not agree to the new terms,
          you are no longer authorized to use the Service.
        </p>

        <p className='text'>
          <b>Contact Us</b>
        </p>
        <p className='text'>
          If you have any questions about these Terms, please <a href="mailto:pettrait.3week.net@gmail.com?body=1. I have some suggestions to share with you:%0D%0A%0D%0A%0D%0A%0D%0A 2. I want complain about:%0D%0A%0D%0A%0D%0A%0D%0A 3. I love Inpiration about:%0D%0A%0D%0A%0D%0A">contact us.</a>
        </p>
      </Grid>
    </Grid>
  )
}