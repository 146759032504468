import IconSearch from '../../assets/images/IconSearch.svg'
import IconChevronDown from '../../assets/images/IconChevronDown.svg'
import IconPlus from '../../assets/images/IconPlus.svg'
import IconArrowDropDown from '../../assets/images/IconArrowDropDown.svg'
import IconClose from '../../assets/images/IconClose.svg'
import IconVerified from '../../assets/images/IconVerified.svg'
import IconReject from '../../assets/images/IconReject.svg'
import IconError from '../../assets/images/IconError.svg'
import IconArrowDropDownError from '../../assets/images/IconArrowDropDownError.svg'
import IconShareActive from '../../assets/images/IconShareActive.svg'
import IconArrowLeft from '../../assets/images/IconArrowLeft.svg'
import IconTwoPeople from '../../assets/images/IconTwoPeople.svg'
import IconPlusActive from '../../assets/images/IconPlusActive.svg'
import PetTraitIcon from '../../assets/images/PetTraitIcon.svg'
import IconAngleDown from '../../assets/images/IconAngleDown.svg'
import IconSearchLarge from '../../assets/images/IconSearchLarge.svg'
import IconShoppingCart from '../../assets/images/IconShoppingCart.svg'
import IconChooseCustume from '../../assets/images/IconChooseCustume.svg'
import GoodPhotoGuide from '../../assets/images/GoodPhotoGuide.svg'
import TurnRoyalGoodPhotoGuide from '../../assets/images/TurnRoyalGoodPhotoGuide.svg'
import IconGreen from '../../assets/images/IconGreen.svg'
import IconYellow from '../../assets/images/IconYellow.svg'
import AppIcon from '../../assets/images/AppIcon.svg'
import TurnRoyalAppIcon from '../../assets/images/TurnRoyalAppIcon.svg'
import IconApple from '../../assets/images/IconApple.svg'
import Rectangle from '../../assets/images/Rectangle.svg'
import ThemeScreen from '../../assets/images/ThemeScreen.png'
import TurnRoyalThemeScreen from '../../assets/images/TurnRoyalThemeScreen.png'
import StartScreen from '../../assets/images/StartScreen.svg'
import TurnRoyalStartScreen from '../../assets/images/TurnRoyalStartScreen.svg'
import GoldenStar from '../../assets/images/GoldenStar.svg'
import BallWithDots from '../../assets/images/BallWithDots.svg'
import CreamBall from '../../assets/images/BallCream.svg'
import YellowBall from '../../assets/images/BallYellow.svg'
import PinkBall from '../../assets/images/PinkBall.svg'
import BlueBall from '../../assets/images/BlueBall.svg'
import LightPinkBall from '../../assets/images/LightPinkBall.svg'
import Squares from '../../assets/images/Squares.svg'
import Slide1 from '../../assets/images/Slide1.svg'
import TurnRoyalSlide1 from '../../assets/images/TurnRoyalSlide1.svg'
import Slide2 from '../../assets/images/Slide2.svg'
import TurnRoyalSlide2 from '../../assets/images/TurnRoyalSlide2.svg'
import Slide3 from '../../assets/images/Slide3.svg'
import TurnRoyalSlide3 from '../../assets/images/TurnRoyalSlide3.svg'
import EndAdorment from '../../assets/images/EndAdorment.svg'
import Notification from '../../assets/images/Notification.svg'
import NotificationActive from '../../assets/images/NotificationActive.svg'
import Logout from '../../assets/images/Logout.svg'
import Path from '../../assets/images/Path.svg'
import PathModal from '../../assets/images/PathModal.svg'
import LeftArrow from '../../assets/images/LeftArrow.svg'
import ThreeDots from '../../assets/images/ThreeDots.svg'
import LogoMe from '../../assets/images/LogoMe.svg'
import HeartBeat from '../../assets/images/HeartBeat.svg'
import Back from '../../assets/images/Back.svg'
import Notice from '../../assets/images/Notice.svg'
import Plus from '../../assets/images/Plus.svg'
import ThreeDotsActive from '../../assets/images/ThreeDotsActive.svg'
import Facebook from '../../assets/images/facebook.svg'
import Instagram from '../../assets/images/instagram.svg'
import Twitter from '../../assets/images/twitter.svg'
import Pinterest from '../../assets/images/pinterest.svg'
import Dribbble from '../../assets/images/dribbble.svg'
import FacebookBlack from '../../assets/images/facebookBlack.svg'
import TwitterBlack from '../../assets/images/twitterBlack.svg'
import PinterestBlack from '../../assets/images/pinterestBlack.svg'
import DribbbleBlack from '../../assets/images/dribbbleBlack.svg'
import BackGroundHeader from '../../assets/images/BackGroundHeader.png'
import TurnRoyalBackGroundHeader from '../../assets/images/TurnRoyalBackGroundHeader.png'
import IconImage from '../../assets/images/IconImage.svg'
import BabyFoodPhone1 from '../../assets/images/BabyFoodPhone1.png'
import BabyFoodPhone2 from '../../assets/images/BabyFoodPhone2.png'
import LogoBabyFood from '../../assets/images/LogoBabyFood.png'

export const Images = {
  IconSearch,
  IconChevronDown,
  IconPlus,
  IconClose,
  IconArrowDropDown,
  IconVerified,
  IconReject,
  IconError,
  IconArrowDropDownError,
  IconShareActive,
  IconArrowLeft,
  IconTwoPeople,
  IconPlusActive,
  IconAngleDown,
  IconChooseCustume,
  IconGreen,
  IconYellow,
  PetTraitIcon,
  IconSearchLarge,
  IconShoppingCart,
  Rectangle,
  ThemeScreen,
  TurnRoyalThemeScreen,
  StartScreen,
  TurnRoyalStartScreen,
  GoldenStar,
  BallWithDots,
  CreamBall,
  YellowBall,
  PinkBall,
  LightPinkBall,
  BlueBall,
  Squares,
  AppIcon,
  TurnRoyalAppIcon,
  IconApple,
  Slide1,
  TurnRoyalSlide1,
  Slide2,
  TurnRoyalSlide2,
  Slide3,
  TurnRoyalSlide3,
  EndAdorment,
  Notification,
  NotificationActive,
  PathModal,
  GoodPhotoGuide,
  TurnRoyalGoodPhotoGuide,
  TurnRoyalBackGroundHeader,
  Logout,
  Path,
  LeftArrow,
  ThreeDots,
  LogoMe,
  HeartBeat,
  Back,
  Notice,
  Plus,
  ThreeDotsActive,
  Facebook,
  Instagram,
  Twitter,
  Pinterest,
  Dribbble,
  FacebookBlack,
  TwitterBlack,
  PinterestBlack,
  DribbbleBlack,
  BackGroundHeader,
  IconImage,
  BabyFoodPhone1,
  BabyFoodPhone2,
  LogoBabyFood,
}
